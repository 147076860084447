import { Pipe, PipeTransform } from '@angular/core';
import { urlifyTitle } from './urlify-title';

@Pipe({
  name: 'urlifyTitle',
  standalone: true,
})
export class UrlifyTitlePipe implements PipeTransform {
  transform(title: string): string {
    return urlifyTitle(title);
  }
}
